// extracted by mini-css-extract-plugin
export var arrowAnimation = "Banner-module--arrowAnimation--a9c50";
export var backgroundImg = "Banner-module--backgroundImg--2d63e";
export var bannerCurve = "Banner-module--bannerCurve--77e87";
export var bannerHeading = "Banner-module--bannerHeading--8f543";
export var btn = "Banner-module--btn--dbc71";
export var btne = "Banner-module--btne--3dd3b";
export var curve = "Banner-module--curve--2077f";
export var dedicated = "Banner-module--dedicated--7ba9a";
export var dedicatedBanner = "Banner-module--dedicatedBanner--a408f";
export var dedicatedHeading = "Banner-module--dedicatedHeading--89375";
export var description = "Banner-module--description--f000e";
export var innerDedicated = "Banner-module--innerDedicated--7b7c9";
export var newBtn = "Banner-module--newBtn--a32cb";
export var sliderButtons = "Banner-module--sliderButtons--b07c7";
export var subTitle = "Banner-module--subTitle--ce1bd";