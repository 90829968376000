// extracted by mini-css-extract-plugin
export var arrowAnimation = "IdealEngagement-module--arrowAnimation--9935f";
export var black = "IdealEngagement-module--black--c5094";
export var blue = "IdealEngagement-module--blue--c9d06";
export var card = "IdealEngagement-module--card--b6d14";
export var card1 = "IdealEngagement-module--card1--1c459";
export var card2 = "IdealEngagement-module--card2--e62a2";
export var card3 = "IdealEngagement-module--card3--9c779";
export var colGap = "IdealEngagement-module--colGap--3b2d7";
export var concerns = "IdealEngagement-module--concerns--bad64";
export var description = "IdealEngagement-module--description--e38e1";
export var heading = "IdealEngagement-module--heading--e046c";
export var newBtn = "IdealEngagement-module--newBtn--ed57a";
export var progress = "IdealEngagement-module--progress--acdcc";
export var progressSec = "IdealEngagement-module--progressSec--9ffb9";
export var subDescription = "IdealEngagement-module--subDescription--6e445";
export var subHeading = "IdealEngagement-module--subHeading--d00c4";
export var talkBtn = "IdealEngagement-module--talkBtn--ea8d1";
export var white = "IdealEngagement-module--white--811f4";