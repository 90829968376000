import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./IdealEngagement.module.scss"


const IdealEngagement = ({ strapiData }) => {
  return (
    <div className={styles.progressSec}>
      <Container>
        <div className={styles.progress}>
          <h2 className={styles.heading}
            dangerouslySetInnerHTML={{
              __html: strapiData?.title,
            }}
          />
          <p className={styles.subDescription} 
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
          />
          <Row >
            {strapiData?.cards?.map((item, index) => (
              <Col
                xl={4}
                lg={6}
                md={6}
                sm={12}
                key={index}
                className={`d-flex`}
              >
                <div className={`${styles.card} ${styles[`card${index + 1}`]}`}>
                  <h3
                    className={`${styles.subHeading} ${index === 1 ? styles.blue : styles[item.textColor]
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: item?.title,
                      }}
                  >
                  </h3>
                  <p
                    className={`${styles.description} ${index === 1 ? styles.white : styles.black
                    }`}
                  >
                    {item?.subTitle}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        {/* <div className={styles.concerns}>
          <Row className="justify-content-between align-items-center">
            <h2>Estimate a Prototype or Get a Product Development Roadmap</h2>

            <div className={styles.cardFooter}>
              <Link
                to={"/contact-us/"}
                className={styles.newBtn}
                style={{ color: "white" }}
              >
                Schedule A Call
              </Link>
            </div>
          </Row>
        </div> */}
      </Container>
    </div>
  )
}

export default IdealEngagement;
