import { graphql, Script } from "gatsby"
import React from "react"
import ContactSales from "../../components/common/ContactSales"
// import Faqs from "../../components/common/Faqs2"
import MainLayout from "../../layouts/MainLayout3"
import IndustriesWeServe from "../../components/data-engineering-services/IndustriesWeServe"
import Banner from "../../components/data-engineering-services/Banner"
import TrustInvoZone from "../../components/home-sections/ClientLogos"
import ScopOfEmergingTech from "../../components/nlp-services/NLPExpertise"
import OurProcess from "../../components/ai-development-services-1/OurApproach"
import WordsFromClients from "../../components/ai-development-services-1/WordsFromClients"
import SEORevamp from "../../components/common/SEO_Revamp"
import AIStatistics from "../../components/data-engineering-services/AIStatistics"
import OurDataMigration from "../../components/data-engineering-services/OurDataMigration"
import WhyChooseUs from "../../components/data-engineering-services/WhyChooseUs"
import Faqs from "../../components/common/Faqs2"
import IdealEngagement from "../../components/data-engineering-services/IdealEngagement"

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })
  return (
    <>
      <Script
        src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"
        strategy="post-hydrate"
      />

      <SEORevamp
        title={metaTitle}
        description={metaDescription}
        schemas={headSchema}
        image="https://invozone-backend.s3.amazonaws.com/hire_elixir_developers_f701d7b38c.webp"
      />
    </>
  )
}

const DataEngineeringAI = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])



  const heroSection = data?.strapiPage?.sections[0]
  const aiStatistics = data?.strapiPage?.sections[2]
  const dataMigration = data?.strapiPage?.sections[3]
  const ourProcess = data?.strapiPage?.sections[4]
  const keyComponents = data?.strapiPage?.sections[5]
  const industriesWeServe = data?.strapiPage?.sections[6]
  const whyChoose = data?.strapiPage?.sections[7]
  const longTerm = data?.strapiPage?.sections[8]
  const wordsFromClient = data?.strapiPage?.sections[9]
  const faqs = data?.strapiPage?.sections[10]



  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })


  return (
    <MainLayout bgChanged={true} schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      <TrustInvoZone hideCurve={true} />
      <AIStatistics strapiData={aiStatistics} />
      <OurDataMigration strapiData={dataMigration} />
      <OurProcess strapiData={ourProcess} />
      <ScopOfEmergingTech strapiData={keyComponents} />
      <IndustriesWeServe strapiData={industriesWeServe} />
      <WhyChooseUs strapiData={whyChoose} />
      <IdealEngagement strapiData={longTerm} />
      <WordsFromClients strapiData={wordsFromClient} />
      <Faqs strapiData={faqs} pageName="Staff Augmentation Company" />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "data-engineering-services" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          title2
          subTitle
          jsonData{
          aiPowered {
              services{
                serLink
                serName
              }
            }
          } 
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
          
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
  }
`

export default DataEngineeringAI;

// export const Head = ({ data }) => {
//   const { metaTitle, metaDescription } = data?.strapiPage?.seo
//   let headSchema = []
//   const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
//     return v?.visibilityIn === true ? v : headSchema?.push(v) && false
//   })
//   return (
//     <SEORevamp
//       title={metaTitle}
//       description={metaDescription}
//       schemas={headSchema}
//       image="https://invozone-backend.s3.amazonaws.com/staff_augmentation_company_7031a2796c.svg"
//     />
//   )
// }
