import React from "react"
import * as styles from "./IndustriesWeServe.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
import "./IndustriesWeServe.scss"
import Slider from "react-slick"
import { Col } from "react-bootstrap"

function Arrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainer}>
      <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/right_f5df90e3bd.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}
function PrevArrow(props) {
  const { onClick } = props
  return (
    <div className={styles.iconContainerLeft}>
      <div className={styles.portfolioArrowIconCover} onClick={onClick}>
        <img
          decoding="async"
          loading="lazy"
          alt="arrow"
          src={
            "https://invozone-backend.s3.us-east-1.amazonaws.com/left_ccefba5791.svg"
          }
          className={styles.portfolioArrowIcon}
        />
      </div>
    </div>
  )
}

const IndustriesWeServe = ({ strapiData, projectEngRTest }) => {
  const data = [
    {
      name: "Healthcare",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/healthcare_b27e93b03c.webp",
      button: "/healthcare/",
      button2: "Explore More in Healthcare",
      skills: [
        "Telemedicine Platforms",
        "Healthcare CRM",
        "Medical Billing Applications",
        "Patient Portals",
      ],
    },
    {
      name: "Fintech",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/fintech_883a1cfb59.webp",
      button: "/fintech/",
      button2: "Explore More in Fintech",
      skills: [
        "Payment Gateways",
        "Digital Banking",
        "Lending Platforms",
        "Personal Finance Management",
      ],
    },
    {
      name: "Food & Grocery",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/food_and_groery_4a8bedd606.webp",
      button: "/food-groceries/",
      button2: "Explore More in Food & Grocery",
      skills: [
        "Grocery Delivery Apps",
        "Meal Kit Delivery Services",
        "Recipe And Cooking Apps",
        "Food Waste Management Apps",
        // "Restaurant management software",
      ],
    },
    {
      name: "E-commerce",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/ecommerce_a8c373431e.webp",
      button: "/e-commerce/",
      button2: "Explore More in E-commerce",
      skills: [
        "Social Commerce",
        "Subscription-Based E-commerce",
        "Marketplace Platforms",
        "Digital Goods E-commerce",
      ],
    },
    {
      name: "Education",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Education_6a8002028d.webp",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/education_592ecc3d5c.webp",
      button: "/education/",
      button2: "Explore More in Education",
      skills: [
        "Learning Management Systems",
        "Online Course Platforms",
        "Massive Open Online Courses",
        "Tutoring and Homework Help Apps",
      ],
    },
    {
      name: "Travel & Tourism",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Travel_and_Tourism_ba6f871cd3.webp",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/travel_5213ff55c0.webp",
      button: "/travel-tourism/",
      button2: "Explore More in Travel & Tourism",
      skills: [
        "Travel Booking Apps",
        "Flight and Hotel Comparison Tools",
        "Trip Planning Apps",
        "Travel Guides and Itinerary Apps",
      ],
    },
    {
      name: "Real Estate",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Real_Estate_2e54bd437e.webp",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/realestate_544e623be9.webp",
      button: "/contact-us/",
      button2: "Explore More in Estate",
      skills: [
        "Property Listing Apps",
        "Real Estate Marketplaces",
        "Rental Management Apps",
        "Real Estate Investment Platforms",
      ],
    },
    {
      name: "PropTech",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/On_demand_f9b84b5748.webp",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007680_1_19d09f6f39.webp",
      button: "/contact-us/",
      button2: "Explore More in On-demand",
      skills: [
        "Property Management Software",
        "Smart Building Technologies",
        "Virtual And Augmented Reality Apps",
        "Tenant Communication Platforms",
      ],
    },
    {
      name: "On-demand",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/On_demand_f9b84b5748.webp",
      image2:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007681_1_f617eaaae0.webp",
      button: "/on-demand-services/",
      button2: "Explore More in On-demand",
      skills: [
        "SuperApps",
        "Food Delivery Services",
        "Ride-Hailing Apps",
        "Home Services",
      ],
    },
  ]

  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 4,
    // centerMode: true,
    centerPadding: "0px",
    slidesToScroll: 3,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          slidesToShow: 3,
          arrows: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "clients-dots",
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: "clients-dots",
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <Row className={styles.headingAndDesc}>
            <Col xs={12} sm={12} md={12} lg={5} xl={4}>
              <p className={styles.heading}
                 dangerouslySetInnerHTML={{
                  __html: strapiData?.title,
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={5} xl={6}>
              <p
                 className={styles.description}
                 dangerouslySetInnerHTML={{
                  __html: strapiData?.subTitle,
                }}
              />
            </Col>
          </Row>
          <Row className="align-items-center clientSlider">
            <Slider {...settings} className={styles.SliderWidth}>
              {data?.map((el, i) => (
                <div key={i} className={styles.slideWrapper}>
                  <div
                    className={`${styles.cards} ${i % 2 === 0 ? styles.greyCard : styles.redCard
                      } ${styles.cardHover}`}
                  >
                    <img src={el?.image2} className={styles.image2} />
                    <div className={styles.cardContent}>
                      <p className={styles.cardHeading}>{el?.name}</p>
                      <p className={styles.dec}>{el?.description}</p>
                      <div className={styles.points}>
                        {el.skills &&
                          el.skills.map((skill, eIdx) => (
                            <div key={eIdx} className={styles.arrowPoint}>
                              <img
                                src="https://invozone-backend.s3.us-east-1.amazonaws.com/dot_a41563b685.svg"
                                decoding="async"
                                loading="lazy"
                                alt=""
                              />
                              <p className={`${styles.skill}`}>{skill}</p>
                            </div>
                          ))}
                      </div>
                      <div className={styles.cardFooter}>
                        <Link to={el?.button} className="newBtn">
                          Explore More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default IndustriesWeServe
