// extracted by mini-css-extract-plugin
export var SliderWidth = "WhyChooseUs-module--SliderWidth--aedbe";
export var Trust = "WhyChooseUs-module--Trust--b512e";
export var card = "WhyChooseUs-module--card--c9244";
export var cardHeading = "WhyChooseUs-module--cardHeading--b8ca7";
export var desc = "WhyChooseUs-module--desc--e8012";
export var description = "WhyChooseUs-module--description--91c80";
export var heading = "WhyChooseUs-module--heading--1670c";
export var headingAndDesc = "WhyChooseUs-module--headingAndDesc--1bdc7";
export var iconContainer = "WhyChooseUs-module--iconContainer--10306";
export var iconContainerLeft = "WhyChooseUs-module--iconContainerLeft--f442c";
export var imgContainer = "WhyChooseUs-module--imgContainer--f67c4";
export var portfolioArrowIcon = "WhyChooseUs-module--portfolioArrowIcon--2e8ad";
export var portfolioArrowIconCover = "WhyChooseUs-module--portfolioArrowIconCover--8dee0";
export var portfolioArrowRightIconCover = "WhyChooseUs-module--portfolioArrowRightIconCover--73f2c";
export var sheildImgTop = "WhyChooseUs-module--sheildImgTop--c4d5b";
export var shieldTopBannerImg = "WhyChooseUs-module--shieldTopBannerImg--24979";