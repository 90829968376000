// extracted by mini-css-extract-plugin
export var SliderWidth = "IndustriesWeServe-module--SliderWidth--1c829";
export var Trust = "IndustriesWeServe-module--Trust--15030";
export var arrowPoint = "IndustriesWeServe-module--arrowPoint--f741d";
export var blurInOut = "IndustriesWeServe-module--blurInOut--21e76";
export var buton = "IndustriesWeServe-module--buton--53c4f";
export var cardContent = "IndustriesWeServe-module--cardContent--3cb15";
export var cardFooter = "IndustriesWeServe-module--cardFooter--7dc68";
export var cardHeading = "IndustriesWeServe-module--cardHeading--8ddad";
export var cardHover = "IndustriesWeServe-module--cardHover--f2b35";
export var cards = "IndustriesWeServe-module--cards--5f39d";
export var description = "IndustriesWeServe-module--description--27e3e";
export var greyCard = "IndustriesWeServe-module--greyCard--72e82";
export var heading = "IndustriesWeServe-module--heading--80b6a";
export var headingAndDesc = "IndustriesWeServe-module--headingAndDesc--1cf89";
export var iconContainer = "IndustriesWeServe-module--iconContainer--c2de2";
export var iconContainerLeft = "IndustriesWeServe-module--iconContainerLeft--0b4b7";
export var image2 = "IndustriesWeServe-module--image2--4f1ab";
export var indDec = "IndustriesWeServe-module--indDec--345ba";
export var newBtn = "IndustriesWeServe-module--newBtn--2ad5b";
export var points = "IndustriesWeServe-module--points--3327b";
export var portfolioArrowIcon = "IndustriesWeServe-module--portfolioArrowIcon--763f6";
export var portfolioArrowIconCover = "IndustriesWeServe-module--portfolioArrowIconCover--647b1";
export var portfolioArrowRightIconCover = "IndustriesWeServe-module--portfolioArrowRightIconCover--d92bc";
export var redCard = "IndustriesWeServe-module--redCard--d0ab1";
export var sheildImgTop = "IndustriesWeServe-module--sheildImgTop--bb76b";
export var shieldTopBannerImg = "IndustriesWeServe-module--shieldTopBannerImg--75c8b";
export var skill = "IndustriesWeServe-module--skill--0c7d9";
export var text = "IndustriesWeServe-module--text--bb6c0";